import React from "react";
import { Header } from "../Header/Header";
import "./Hero.css";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import { motion } from "framer-motion";
import NumberCounter from "number-counter";

export const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile = window.innerWidth <= 768 ? true : false;
  return (
    // hero component start
    <div className="hero" id="home">
      <div className="blur hero-blur"></div>
      {/* left-side start */}
      <div className="left-side">
        {/* importing header from here  */}
        <Header />
        {/* div with animation */}
        <div className="the-best-ad">
          <motion.div
            initial={{ left: mobile ? "178px" : "238px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
          ></motion.div>
          <span>The best fitness club in the town</span>
        </div>
        {/* hero text is starting from where */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">Shape </span>
            <span> your</span>
          </div>

          <div>
            <span>ideal </span>
            <span>body</span>
          </div>
          <div>
            <span>
              In here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/* hero text end here */}
        {/* figures starting from here */}
        <div className="figures">
          <div>
            <span>
              <NumberCounter start={100} end={140} delay="4" preFix="+" />
            </span>
            <span>Expert Coaches</span>
          </div>
          <div>
            <span>
              {" "}
              <NumberCounter start={800} end={978} delay="4" preFix="+" />
            </span>
            <span>Members Joined</span>
          </div>
          <div>
            <span>
              {" "}
              <NumberCounter start={0} end={50} delay="4" preFix="+" />
            </span>
            <span>Programs</span>
          </div>
        </div>
        {/* figures end here */}
        {/* Hero Buttons  */}
        <div className="hero-btns">
          <button className="btn">Get started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      {/* left side ends here */}
      <div className="right-side">
        {/* start of right side */}
        <button className="btn">Join Now</button>
        {/* heart rate  */}
        <motion.div
          className="heart-rate"
          initial={{ right: "-1.5rem" }}
          whileInView={{ right: "4rem" }}
          transition={transition}
        >
          <img src={Heart} alt="" />
          <span>Heart rate</span>
          <span>116 Bpm</span>
        </motion.div>
        {/* hero images  */}
        <img src={hero_image} alt="" className="hero_image" />
        <motion.img
          src={hero_image_back}
          className="hero_image_back"
          initial={{ right: "11rem" }}
          whileInView={{ right: "20rem" }}
          transition={transition}
        />
        {/* Calories  */}
        <motion.div
          className="calories"
          initial={{ right: "37rem" }}
          whileInView={{ right: "28rem" }}
          transition={transition}
        >
          <img src={Calories} alt="" />
          <div>
            <span>Calories burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
      {/* end of hero component */}
    </div>
  );
};
